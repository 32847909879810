const ubikJsonIds = ['ubik-user-context', 'ubik-context-tracking']

export function sanitizeLocalContext () {
  for (const id of ubikJsonIds) {
    const all = document.querySelectorAll(`#${id}`)
    if (all.length > 1) {
      for (let i = 0; i < all.length; i++) {
        if (i < all.length - 1) {
          all[i].remove()
        }
      }
    }
  }
}
