import { isAuthDevelopmentMode } from '../local-runtime'
import type { UbikRuntime } from '../create'
import { measure } from '../measure'
import { reportRuntimeError } from './report-errors'
import { UserContext } from '@vp/ubik-fragment-types'
import type { VPAuthConfig } from '@vp/ubik-context'

export const authInit = async (runtime: UbikRuntime, userContext: UserContext) => {
  try {
    measure(async () => {
      const vpauth = await System.import<VPAuth>('@vp/auth')
      const init = vpauth.init ?? vpauth.default.init

      const vpAuthConfig: VPAuthConfig = {
        culture: userContext.locale,
        developmentMode: isAuthDevelopmentMode(),
        enableCrossSiteSso: true,
        options: {
          requireSession: true,
          ...(userContext.tenant === 'vcs' && {
            // TODO - move clientID setting to @vp/auth. If 'site' is VistaPrint, use VP clientID, if site is 'vcs', use VCS clientID; https://vistaprint.atlassian.net/browse/PSHOP-5657
            clientID: 'nMpxos502IwOcaAuKEfKKNcOOV4wXTXR',
            site: userContext.tenant,
            storeId: userContext.subtenant,
            vcsStoreUrl: document?.location?.host,
            // TODO - determine how to fetch store logo url - through a ubik header?; https://vistaprint.atlassian.net/browse/PSHOP-5658
            vcsStoreLogoUrl: '',
          }),
        },
      }

      init(
        vpAuthConfig,
        function success () {
          const VpWebAuth = vpauth.WebAuth ?? vpauth.default.WebAuth

          const auth = new VpWebAuth()
          runtime.setIdentity({ auth })
          const isCustomer = auth.getProfile()['https://claims.cimpress.io/is_customer'] !== undefined

          auth.onUserIdentityUpdate((newIdentity?: VPAuthWebAuth) => {
            if (!newIdentity) {
              // don't update the context if the new identity is undefined
              return
            }

            const isSignedIn = Boolean(newIdentity.authorizationHeader) && newIdentity.isSignedIn

            const savedIdentity = {
              auth,
              signIn: () => auth.signIn(),
              signOut: () => auth.signOut(),
              isIdentityInitialized: true,
              identity: newIdentity,
              isCustomer,
              isSignedIn,
            }
            runtime.setIdentity(savedIdentity)
          })
        },
        function error (err?: Error) {
          const error = err ?? new Error('error initializing auth')
          reportRuntimeError(error, 'vp-auth')
          console.log('error initializing auth')
        }
      )
    }, 'auth-init')
  } catch (err) {
    reportRuntimeError(err as Error, 'vp-auth')
    console.log('error initializing auth')
  }
}

export interface VPAuth {
  init?: VPAuthInit
  WebAuth?: VPAuthWebAuth
  default: {
    init: VPAuthInit
    WebAuth: VPAuthWebAuth
  }
}

export type Identity = {
  auth: VPAuthWebAuth;
  signIn: () => void;
  signOut: () => void;
  isIdentityInitialized: boolean;
  identity: VPAuthWebAuth;
  isCustomer: boolean;
  isSignedIn: boolean;
}

export type VPAuthInit = (config: any, success: (identity: any) => void, error: () => void) => void
export type VPAuthWebAuth = {
  new (): VPAuthWebAuth
  getIdentity: () => any
  getProfile: () => {
    'https://claims.cimpress.io/is_customer': boolean
  }
  getCanonicalId: () => string
  onUserIdentityUpdate: (callback: (newIdentity: any) => void) => void
  authorizationHeader: string
  isSignedIn: boolean
  signIn: () => void
  signOut: () => void
}
