const round = (val: number) => Math.trunc(val * 100) / 100

export const report = () => {
  const url = new URL(window.location.href)
  const log = url.searchParams.get('debug')
  if (log) {
    const measures = performance.getEntriesByType('measure')
    const report = measures.reduce<
      Record<string, { startTime: number; duration: number }>
    >((acc, measure) => {
      acc[measure.name] = {
        startTime: round(measure.startTime),
        duration: round(measure.duration),
      }
      return acc
    }, {})

    console.table(report)
  }
}
