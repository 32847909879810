/// <reference types="systemjs" />
import { measure } from '../measure'
import { getContextTracking } from './user-context'
import { reportRuntimeError } from './report-errors'

export const abTestInit = async () => {
  try {
    const reader = await System.import<ABReader>('@vp/ab-reader')
    await measure(async () => {
      reader.initialize()

      const available = await measure(() => reader.waitTillAvailable(), 'ubik-ab-reader wait')

      if (available) {
        const contextTracking = getContextTracking()

        const experiments = Object.keys(contextTracking.experiments)
        experiments.forEach((experiment) => {
          const variation = contextTracking.experiments[experiment]
          reader.fireImpression(experiment.toString(), variation)
        })

        const featureFlags = Object.keys(contextTracking.featureFlags)
        featureFlags.forEach(featureFlagName => {
          reader.isFeatureEnabled(featureFlagName)
        })
      }
    }, 'ubik-ab-reader init')
  } catch (err) {
    reportRuntimeError(err, 'ab-reader')
    console.log('error initializing ab-reader')
  }
}

type ABReader = {
  initialize: () => void
  whenAvailable: (callback: (available?: boolean | undefined) => void, timeout?: number | undefined) => void;
  waitTillAvailable: (timeout?: number | undefined) => Promise<boolean>;
  fireImpression: (experimentKey: string, variationKey: string, pageParameters?: PageParameters | null | undefined) => boolean;
  isFeatureEnabled: (featureName: string) => boolean;
}

interface PageParameters {
  pageName?: string;
  pageSection?: string;
  pageStage?: string;
}
