import { measure } from './measure'
import { reportRuntimeError } from './init/report-errors'
import type { FragmentRootOptions, MountWithRootOptions, RuntimeEvent, MountRuntimeEvent } from '@vp/ubik-fragment-types'

/**
 * @deprecated, prefer MountRuntimeEvent instead
 */
type DeprecatedLifecycleFunctions = {
  bootstrap?: () => (element: HTMLElement) => Promise<void>
  mount?: () => (element: HTMLElement, options: FragmentRootOptions) => Promise<{ unmount?: () => void }>
}

/**
 * @deprecated, prefer RuntimeEvent instead
 */
export type DeprecatedFragmentMount = [
  elementId: string,
  lifecycle: DeprecatedLifecycleFunctions,
  fragmentOptions?: FragmentRootOptions
]

export type FragmentEntry = RuntimeEvent | DeprecatedFragmentMount

// PCI compliance goals
// 1. integrity checks for scripts
// 2. remove the need for dynamically generated scripts
// 3. pass data from document to runtime script via script application/json instead of script text
// 4. runtime will use data conventions to load fragments
// 5. runtime itself could then be loaded via script src, and not be inlined in the document

// this is a replacement to `lifecycle.mount` function generated by the fragment,
// createClientMount wrapper function in ubik-fragment-react

// this is work in progress for enabling PCI compliance with fragments scripts, by reducing the number
// of dynamically generated scripts, and instead rely on data conventions instead
const clientMount = async (element: HTMLElement, mountRuntimeEvent: MountRuntimeEvent) => {
  const { fragmentId, data, rootOptions } = mountRuntimeEvent
  try {
    const { mount } = await System.import<{ mount: MountWithRootOptions<object> }>(fragmentId)

    if (!mount || typeof mount !== 'function') {
      throw new Error(`Invalid module, "mount" not implemented in ${fragmentId}`)
    }

    return mount(element, data, rootOptions)
  } catch (err) {
    console.error(`Failed to load ${fragmentId}`, {
      err
    })
    throw err
  }
}

const mountedFragments = new Set<string>()

const validateMount = (rootElementId: string, fragmentId = 'legacy') => {
  if (mountedFragments.has(rootElementId)) {
    console.warn(`Fragment already mounted: ${rootElementId} for fragmentId: ${fragmentId}`)
    reportRuntimeError(new Error('Fragment already mounted'), 'mount', {
      rootElementId,
      fragmentId
    })
    return false
  } else {
    mountedFragments.add(rootElementId)
    return true
  }
}

export const querySelector = (elementId: string) => document.querySelector<HTMLElement>(`#${CSS.escape(elementId)}`)

/**
 *  @deprecated, prefer mountFragmentEntry instead
 */
const createMountDeprecatedFragmentEntry = (fragmentEntry: DeprecatedFragmentMount): (() => Promise<{ unmount?: () => void }>) | undefined => {
  const [rootElementId, lifecycleFunctions, fragmentOptions = {}] = fragmentEntry
  const element = querySelector(rootElementId)

  const wrappedFunction = lifecycleFunctions['mount']
  if (!wrappedFunction || !element) {
    return undefined
  }

  const mountFunction = wrappedFunction()
  if (!mountFunction) {
    return undefined
  }

  if (!validateMount(rootElementId)) {
    return undefined
  }

  return async function () {
    return measure(
      () => mountFunction(element, fragmentOptions),
        `mount:${rootElementId}`,
        { detail: rootElementId }
    )
  }
}

const isMountRuntimeEvent = (runtimeEvent: RuntimeEvent): runtimeEvent is MountRuntimeEvent => {
  return runtimeEvent.event === 'mount'
}

const createMountFragmentEntry = (fragmentEntry: RuntimeEvent) => {
  if (!isMountRuntimeEvent(fragmentEntry)) {
    return undefined
  }

  const { rootElementId, fragmentId } = fragmentEntry
  const element = querySelector(rootElementId)

  if (!element) {
    return undefined
  }

  if (!validateMount(rootElementId, fragmentId)) {
    return undefined
  }

  return async function () {
    return measure(
      () => clientMount(element, fragmentEntry),
      `mount:${fragmentId}:${rootElementId}`,
      {
        detail: {
          fragmentId,
          rootElementId
        }
      }
    )
  }
}

const createMount = (fragmentEntry : FragmentEntry) => {
  if (Array.isArray(fragmentEntry)) {
    return createMountDeprecatedFragmentEntry(fragmentEntry)
  } else {
    return createMountFragmentEntry(fragmentEntry)
  }
}

export const load = async (fragments: FragmentEntry[]): Promise<unknown[]> => {
  const results: unknown[] = []
  const mountFunctions = fragments.map(createMount).filter((mount): mount is (() => Promise<{
    unmount?: () => void;
  }>) => !!mount)
  for (const mount of mountFunctions) {
    try {
      const result = await mount()
      results.push({ status: 'fulfilled', value: result })
    } catch (error) {
      results.push({ status: 'rejected', reason: error })
    }
  }
  return results
}
