// use the base s.js module instead of the full system.js, to avoid extra/global.js
// extra/global seems to be made to detect and return an object from an imported
// script which is not a SystemJS module, we don't want this behavior

// note: we lose the ability to do runtime introspection of the registry (features/registry.js)
// as well as the default json, css and wasm loaders (extras/modules-types.js)
// a simpler JSON loader is implemented in `import` below.

import 'systemjs/dist/s.js'
import { measure } from './measure'
import { reportRuntimeError } from './init/report-errors'

const jsonContentType = /^application\/json(;|$)/

const systemJSPrototype = System.constructor.prototype

const originalImport = systemJSPrototype.import
systemJSPrototype.import = async function (moduleId: string, parentUrl?: string) {
  // implement JSON loader, which was implemented by systemjs/extras/modules-types.js
  // it appears the module-types extra may introduce a bug where SystemJS modules
  // are not properly making a strong guarantee that the System.require of a module evaluation
  // is synchronous get a call to getRegister which is a requirement for the SystemJS spec
  if (moduleId.endsWith('.json')) {
    return measure(async () => {
      const url = systemJSPrototype.resolve(moduleId, parentUrl)
      const res = await fetch(url)

      if (!res.ok) {
        const error = new Error('Failed to load JSON module')
        reportRuntimeError(error, 'import', {
          moduleId,
          url,
          status: res.status.toString(),
        })
        throw error
      }
      const contentType = res.headers.get('content-type') ?? ''
      if (jsonContentType.test(contentType) === false) {
        const error = new Error('Invalid JSON content-type')
        reportRuntimeError(error, 'import', {
          moduleId,
          url,
          contentType,
        })
        throw error
      }

      return res.json()
    }, `import(${moduleId})`)
  }

  if (!parentUrl) {
    return measure(() => originalImport.call(this, moduleId, parentUrl), `import(${moduleId})`)
  } else {
    return originalImport.call(this, moduleId, parentUrl)
  }
}
