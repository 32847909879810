import { isAuthDevelopmentMode } from '../local-runtime'
import { getMarket } from './user-context'
import type { UbikRuntime } from '../create'
import { measure } from '../measure'
import { reportRuntimeError } from './report-errors'

export const pricingContextInit = (runtime: UbikRuntime): void => {
  System.import<PricingContextModuleWrapper>('@vp/ubik-pricing-context').then(
    (module) => {
      return measure(() => {
        const pricingContextModule = module.init ? module as PricingContextModule : module.default
        const identity = runtime.getIdentity()
        if (identity?.isIdentityInitialized) {
          initializePricing(pricingContextModule)
        } else {
          runtime.onIdentityChange(() => {
            if (runtime.getIdentity()?.isIdentityInitialized) {
              initializePricing(pricingContextModule)
              return true
            }
            return false
          })
        }
      }, 'pricing-context-init')
    }
  ).catch((err) => {
    reportRuntimeError(err, 'pricing-context')
    console.log('error initializing pricing-context')
  })
}

async function initializePricing (pricingContextModule: PricingContextModule) {
  await pricingContextModule.init('vistaprint', getMarket(), isAuthDevelopmentMode())
}

type PricingContextModuleWrapper = Partial<PricingContextModule> & {
  default: PricingContextModule
}

type PricingContextModule = {
  init: (merchantId: string, market: string, isAuthDevelopmentMode: boolean) => Promise<PricingContext>
}

type PricingContext = {
  version: number,
  merchantId?: string,
  market?: string,
  couponCode?: string,
  effectiveDateTime?: string,
  customerGroups?: string[],
  vatInclusive?: boolean,
  expiresAt?: Date,
  developmentMode?: boolean,
  shopperId?: string,
}
