import { authInit } from './auth'
import { trackingInit } from './tracking'
import { abTestInit } from './ab-reader'
import { pricingContextInit } from './pricing-context'
import { newRelicInit } from './new-relic'
import type { UbikRuntime } from '../create'
import { getUserContext } from './user-context'
import { reportRuntimeError } from './report-errors'

type InitOptions = {
  setLoadThirdParty: (loadThirdParty: Promise<() => void>) => void
}

export const init = (options: InitOptions, runtime: UbikRuntime) => {
  const userContext = getUserContext()

  newRelicInit(userContext)
  authInit(runtime, userContext)
  trackingInit(options.setLoadThirdParty, userContext).catch((err) => {
    reportRuntimeError(err, 'tracking')
    console.log('error initializing tracking')
  })
  abTestInit()
  pricingContextInit(runtime)
}
