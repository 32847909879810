import { type UbikRuntime } from './create'
import { isRuntimeEvent, parseJsonFromElement } from './events'
import { reportRuntimeError } from './init/report-errors'
import { mimeUbikEvent } from './mime'

export const isCompleted = (node: HTMLScriptElement) => node.dataset['completed'] === 'true'
export const setCompleted = (node: HTMLScriptElement) => { node.dataset['completed'] = 'true' }

export const observeUbikEvents = (push: UbikRuntime['push']) => {
  // don't attempt to parse until at least a minimum length has been reached
  // innerText may not yet be fully loaded from html stream
  const hasSufficientData = (node: HTMLScriptElement) => {
    const expectedLength = parseInt(node.dataset['length'] ?? '0', 10)
    const currentLength = node.innerText.length
    return currentLength >= expectedLength
  }

  const handleNode = (node: HTMLScriptElement): boolean => {
    if (hasSufficientData(node)) {
      const runtimeEvent = parseJsonFromElement(node)
      if (runtimeEvent) {
        setCompleted(node)
        if (isRuntimeEvent(runtimeEvent)) {
          push(runtimeEvent)
        } else {
          reportRuntimeError(new Error('Invalid runtime event'), 'observeUbikEvents')
        }
        return true
      }
    }
    return false
  }

  const scriptBlockObserver = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach((node) => {
        if (node instanceof HTMLScriptElement &&
            node.type === mimeUbikEvent &&
            node.dataset['length'] &&
            !isCompleted(node)) {
          if (handleNode(node)) {
            return
          }

          // Observe changes to the innerText of the script element
          const innerTextObserver = new MutationObserver((mutations, observer) => {
            mutations.forEach((mutation) => {
              if (mutation.type === 'characterData') {
                if (isCompleted(node)) {
                  observer.disconnect()
                  return
                }

                if (handleNode(node)) {
                  observer.disconnect()
                }
              }
            })
          })

          innerTextObserver.observe(node, { characterData: true, subtree: true })
        }
      })
    })
  })

  // observe the document for new events
  scriptBlockObserver.observe(document.body, { childList: true })
}
