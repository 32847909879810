import { reportRuntimeError } from './report-errors'
import { getPageName } from './tracking'
import { getUserContext } from './user-context'
import { measure } from '../measure'
export const chatAnywhereInit = () => {
  return measure(async () => {
    try {
      const context = getUserContext()

      const flagValue = context.featureFlags?.['chat_anywhere_ubik']
      if (flagValue !== 'true') {
        return
      }
      const module = await System.import<ChatAnywhere>('@vp/chat-anywhere-ubik')
      const pageName = getPageName(Array.from(document.querySelectorAll('meta')))
      await module.initChatAnywhere({ locale: context.locale, tenant: context.tenant, pageName })
    } catch (err) {
      reportRuntimeError(err as Error, 'chat-anywhere')
      console.log('error initializing chat-anywhere', err)
    }
  }, 'ubik-chat-anywhere')
}

interface ChatAnywhere {
  initChatAnywhere: (config: {
    locale: string,
    tenant: string,
    pageName: string,
  }) => Promise<any>
}
