import type { RuntimeEvent } from '@vp/ubik-fragment-types'
import { sanitizeLocalContext } from './htmx'
import { init } from './init'
import { Identity } from './init/auth'
import { chatAnywhereInit } from './init/chat'
import { type DeprecatedFragmentMount, type FragmentEntry, load } from './load'
import { measure } from './measure'
import { createDeferred } from './deferred'
import { report } from './report'
import { isReadyEvent, isRuntimeEvent, parseJsonFromElement } from './events'
import { reportRuntimeError } from './init/report-errors'
import { observeUbikEvents, setCompleted } from './observer'
import { mimeUbikEvent } from './mime'

export type UbikRuntimeCallback = (runtime: UbikRuntime) => boolean

export type UbikRuntime = {
  /** @internal */
  onIdentityChange(callback: UbikRuntimeCallback): void;
  /** @internal */
  setIdentity(newIdentity: Partial<Identity>): void;
  /** @internal */
  getIdentity(): Partial<Identity> | null;

  push(...fragments: FragmentEntry[]): void;
  start(): void;
  readonly loadingState: LoadingState;
}

type LoadingState = 'init' | 'started' | 'ready'

const findUbikEvents = () => Array.from(document.querySelectorAll<HTMLScriptElement>(`script[type="${mimeUbikEvent}"][data-length]`))

export function createRuntime (_legacyEntries: DeprecatedFragmentMount[] = []): UbikRuntime {
  const _fragmentEntries: FragmentEntry[] = [..._legacyEntries]

  const _identityCallbacks: UbikRuntimeCallback[] = []

  let loadingState: LoadingState = 'init'

  let identity: Partial<Identity> | null = null

  const { promise: onFirstLoadReady, resolve: setFirstLoadReady } = createDeferred<boolean>()

  return {
    get loadingState (): LoadingState {
      return loadingState
    },
    push (...fragmentEntries: FragmentEntry[]) {
      switch (loadingState) {
        case 'init':
          _fragmentEntries.push(...fragmentEntries)
          break
        default:
          sanitizeLocalContext()
          setTimeout(async () => {
            await load(fragmentEntries)
            if (loadingState !== 'ready') {
              const readyEvent = fragmentEntries.find(isReadyEvent)
              if (readyEvent) {
                loadingState = 'ready'
                setFirstLoadReady(true)
              }
            }
          }, 0)
      }
    },

    onIdentityChange (callback: UbikRuntimeCallback) {
      _identityCallbacks.push(callback)
    },

    setIdentity (newIdentity: Partial<Identity>) {
      const runtime = this
      identity = { ...(identity || {}), ...newIdentity }
      if (identity.auth) {
        for (let i = 0; i < _identityCallbacks.length; i++) {
          if (_identityCallbacks[i](runtime)) {
            _identityCallbacks.splice(i, 1)
            i--
          }
        }
      }
    },

    getIdentity () {
      return identity
    },

    // this was previously set wait for DOMContentLoaded before starting the runtime
    // but we are now starting the runtime earlier, since the additional scripts
    // will be streaming into the document, which would defer the DOMContentLoaded event
    start () {
      const runtime = this

      if (loadingState !== 'init') {
        return
      }

      loadingState = 'started'

      // timeout allows tests to modify System before runtime is started, e.g. to mock modules
      setTimeout(() => {
        const { promise: onThirdPartyReady, resolve: setLoadThirdParty } = createDeferred<() => void>()

        try {
          measure(async () => {
            // enumerate all the scripts already in the document
            const runtimeEvents = findUbikEvents()
            const parsedFragmentEntries = runtimeEvents.map(parseJsonFromElement).filter((script): script is RuntimeEvent => isRuntimeEvent(script))
            runtimeEvents.forEach((node) => {
              setCompleted(node)
            })

            // capture future events
            observeUbikEvents(runtime.push)

            const fragmentEntries = [..._fragmentEntries, ...parsedFragmentEntries]
            _fragmentEntries.length = 0

            init({ setLoadThirdParty }, runtime)
            await load(fragmentEntries)
            if (loadingState !== 'ready') {
              const readyEvent = fragmentEntries.find(isReadyEvent)
              if (readyEvent) {
                loadingState = 'ready'
                setFirstLoadReady(true)
              }
            }
            onFirstLoadReady.then(() => {
              onThirdPartyReady.then((thirdPartyLoader) => {
                measure(() => thirdPartyLoader(), 'thirdPartyLoader')
              })
              chatAnywhereInit()
            })
          }, 'app-init')
        } catch (err) {
          reportRuntimeError(err as Error, 'create runtime')
          console.error('Error during app-init', err)
        }
        report()
      }, 0)
    },
  }
}
