export function measure<T> (fn: () => T, label: string, detail?: Record<string, unknown>): T
export function measure<T> (fn: () => Promise<T>, label: string, detail?: Record<string, unknown>): Promise<T>
export function measure<T> (fn: () => T | Promise<T>, label: string, detail?: Record<string, unknown>): T | Promise<T> {
  const startTime = performance.now()
  const finish = () => performance.measure(label, { start: startTime, detail })

  let result: Promise<T> | T
  try {
    result = fn()
  } catch (e) {
    finish()
    throw e
  }

  if (result instanceof Promise) {
    return result.then((val) => {
      finish()
      return val
    }).catch((e) => {
      finish()
      throw e
    })
  } else {
    finish()
    return result
  }
}
