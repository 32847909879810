import { UserContext } from '@vp/ubik-fragment-types'
import { measure } from '../measure'
export const newRelicInit = (userContext: UserContext) => {
  measure(() => {
    const unknown = 'Unknown'
    const newrelic = (window as any)?.newrelic

    if (newrelic == null || typeof newrelic !== 'object' || typeof newrelic.setCustomAttribute !== 'function') {
      console.log('Could not initialize New Relic')
      return
    }

    const metas = window?.document?.head?.getElementsByTagName('meta')
    const pageName = metas?.namedItem('pageName')?.content
    const pageSection = metas?.namedItem('pageSection')?.content
    const pageStage = metas?.namedItem('pageStage')?.content

    newrelic.setCustomAttribute('pageName', pageName ?? unknown)
    newrelic.setCustomAttribute('pageSection', pageSection ?? unknown)
    newrelic.setCustomAttribute('pageStage', pageStage ?? unknown)

    const { urlId, locale } = userContext

    newrelic.setCustomAttribute('urlId', urlId ?? unknown)
    newrelic.setCustomAttribute('locale', locale)
  }, 'ubik-new-relic')
}
