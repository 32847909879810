import type { RuntimeEvent } from '@vp/ubik-fragment-types'
import type { FragmentEntry } from './load'

export const isRuntimeEvent = (content: unknown): content is RuntimeEvent => {
  return !!content &&
    typeof content === 'object' &&
    'event' in content &&
    typeof content.event === 'string'
}

export const isReadyEvent = (fragmentEntry: FragmentEntry): fragmentEntry is RuntimeEvent => {
  return typeof fragmentEntry === 'object' && 'event' in fragmentEntry && fragmentEntry.event === 'ready'
}

export const parseJsonFromElement = (script: HTMLScriptElement): object | undefined => {
  try {
    return script.textContent ? JSON.parse(script.textContent) : undefined
  } catch (e) {
    return undefined
  }
}
