import type { UserContext } from '@vp/ubik-fragment-types'

const defaultUserContext = {
  locale: 'en-US',
}

export const getUserContext = (): UserContext => {
  const rawData = document?.getElementById('ubik-user-context')
  const innerText = rawData?.innerText
  if (!innerText || innerText.length === 0) {
    console.warn(
      'User context not found with element with id "ubik-user-context"'
    )
    return { ...defaultUserContext }
  }
  try {
    return { ...defaultUserContext, ...JSON.parse(innerText ?? '{}') }
  } catch (e) {
    console.warn(
      'Error parsing user context'
    )
  }
  return { ...defaultUserContext }
}

export const getMarket = () => {
  const userContext = getUserContext()
  return userContext.locale.split('-')[1]
}

export const getContextTracking = () => {
  const rawData = document?.getElementById('ubik-context-tracking')
  const innerText = rawData?.innerText
  if (!innerText || innerText.length === 0) {
    console.warn(
      'User context not found with element with id "ubik-context-tracking"'
    )
    return {
      experiments: {},
      treatments: {},
      featureFlags: {},
      campaigns: [],
    }
  }
  try {
    return JSON.parse(innerText ?? '{}')
  } catch (e) {
    console.warn(
      'Error parsing user context tracking'
    )
  }
  return {
    experiments: {},
    treatments: {},
    featureFlags: {},
    campaigns: [],
  }
}
